* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: "Courier Prime", monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background-color: black;
}

body.no-transition {
    transition: none !important;
}

body.no-transition * {
    transition: none !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

