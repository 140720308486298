h1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.themeButton {
  padding: 0;
  background: none;
  border: none;
  font-size: 20px;

  cursor: pointer;
  outline: none;
}

.langButton {
  margin-left: 5px;
  padding: 0;
  background: none;
  border: none;
  font-size: 20px;

  cursor: pointer;
  outline: none;

  @media (min-width: 900px) {
    margin-left: 10px;
  }
}

.wrapper {
  width: 100%;
  margin: 20px auto;
  padding: 0 10px;

  @media (min-width: 900px) {
    max-width: 900px;
    padding: 0;
  }
}

.line {
  padding: 1px;
  font-size: 18px;
}

.about {
  margin: 20px 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 900px) {
    flex-direction: row;
  }
}

.aboutBox {
  width: 100%;

  padding: 20px;

  line-height: 2rem;

  &:last-child {
    margin-top: 20px;
  }

  @media (min-width: 900px) {
    width: 400px;
    height: 200px;

    &:last-child {
      margin-top: 0;
    }
  }
}

.aboutRow {
  display: flex;
  flex-direction: column;

  @media (min-width: 900px) {
    flex-direction: row;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.table {
  margin: 20px 0;
  font-size: 16px;
}

.row {
  padding: 20px;

  display: flex;
  flex-direction: column;

  &:last-child {
    border-bottom: 0;
  }

  & > div {
    line-height: 1.5;
  }

  @media (min-width: 900px) {
    flex-direction: row;
  }

  & i {
    font-style: normal;

    font-weight: 700;
    color: #869bf0;
  }
}

.years {
  width: 100%;
  padding-bottom: 20px;

  font-weight: 700;

  @media (min-width: 900px) {
    min-width: 150px;
    width: 150px;
    padding-bottom: 0;
  }
}

.tldrLine {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.tldrSectionTitle {
  color: #869bf0;
}

.tldrButton {
  background: none;
  border: 0;
  padding: 3px 7px;

  font-size: 18px;
  font-weight: 600;

  cursor: pointer;
}

.school {
  line-height: 1.5rem;
}

.bold {
  font-weight: 700;
}

.images {
  width: 100%;
  margin-top: 15px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (min-width: 900px) {
  }
}

.img {
  flex: 1 1 auto;
  object-fit: scale-down;

  width: 45%;
  max-height: 500px;

  padding: 10px;
  margin: 10px;

  @media (min-width: 900px) {
    width: 20%;
    max-height: unset;
  }
}

.m104 {
  background-image: url("./img/m104.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  height: 600px;
}

.goodbye {
  font-size: 20px;
  font-weight: 600;
}

/* THEMES */

body {
  background-color: black;
  color: white;

  &.lightTheme {
    background-color: white;
    color: black;
  }

  transition: color, border-color, background-color 1s;
}

a {
  color: white;
  transition: color, border-color, background-color 1s;
}

.wrapper {
  background-color: black;
  color: white;

  transition: color, border-color, background-color 1s;
}

.aboutBox {
  border: 1px solid white;

  transition: color, border-color, background-color 1s;
}

.row {
  border-bottom: 1px solid white;

  transition: color, border-color, background-color 1s;
}

.table {
  border: 1px solid white;

  transition: color, border-color, background-color 1s;
}

.themeButton,
.langButton,
.tldrButton {
  border-color: white;
  color: white;
}

:global {
  .blinking {
    animation: 1s #{"blinkWhite"} step-end infinite;
  }
}

.lightTheme {
  :global {
    .blinking {
      animation: 1s #{"blinkBlack"} step-end infinite;
    }
  }
}

@keyframes :global(blinkWhite) {
  from,
  to {
    color: transparent;
  }
  50% {
    color: white;
  }
}

@keyframes :global(blinkBlack) {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

.lightTheme {
  a {
    color: black;
  }

  .wrapper {
    background-color: white;
    color: black;
  }

  .row,
  .aboutBox,
  .table {
    border-color: black;
  }

  .themeButton,
  .langButton,
  .tldrButton {
    border-color: black;
    color: black;
  }
}
